<template>
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="formType === '0' ? 'sfActive': ''" @click="changeForm('0')">门店信息</div>
      <div v-if="storeInfoEdit !== null" class="sflName" :class="formType === '1' ? 'sfActive': ''" @click="changeForm('1')">门店设置</div>
      <div v-if="storeInfoEdit !== null" class="sflName" :class="formType === '2' ? 'sfActive': ''" @click="changeForm('2')">
        主体认证
        <p v-if="storeInfoEdit !== null" style="display: inline-block;">
            <span v-if="storeInfoEdit.shopauth === true" style="color: #44B557;">（已认证）</span>
            <span v-if="storeInfoEdit.shopauth === false">（未认证）</span>
        </p>
      </div>
    </div>
    <div class="specialFR">
      <!-- 门店信息 -->
      <div class="formItemType" v-if="formType === '0'">
        <p class="formTitle">门店信息</p>
        <el-form :model="storeInfoForm" :rules="storeInfoRule" ref="storeInfo">
          <div class="flexCB">
            <el-form-item label="门店名称" class="detailFormItem"  prop="shopname">
              <el-input v-model="storeInfoForm.shopname"  placeholder="请输入门店名称"></el-input>
            </el-form-item>
            <el-form-item label="开业时间" class="detailFormItem" prop="shoptime">
              <el-date-picker style="width: 100%;" v-model="storeInfoForm.shoptime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开业时间"></el-date-picker>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="负责人" class="detailFormItem"  prop="shopuser">
              <el-input v-model="storeInfoForm.shopuser"  placeholder="请输入负责人"></el-input>
            </el-form-item>
            <el-form-item label="负责人电话" class="detailFormItem"  prop="superphone">
              <el-input v-model="storeInfoForm.superphone"  placeholder="请输入负责人电话"></el-input>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="门店电话" class="detailFormItem"  prop="shophone">
              <el-input v-model="storeInfoForm.shophone"  placeholder="请输入门店电话"></el-input>
            </el-form-item>
            <el-form-item label="地理定位(经纬度)" class="detailFormItem"  prop="logOrLat">
                  <el-input v-model="storeInfoForm.logOrLat" placeholder="例如：108.93949,34.266345">
                    <el-button slot="append" icon="el-icon-location" @click="getLogLat"></el-button>
                  </el-input>
<!--              <el-input v-model="storeInfoForm.logOrLat" placeholder="获取地理定位" disabled>-->
<!--                <el-button slot="append" icon="el-icon-location" @click="selectAddress"></el-button>-->
<!--              </el-input>-->
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="门店区域" prop="province" class="adEl">
              <el-select v-model="storeInfoForm.province" placeholder="省" @change="provinceChange">
                <el-option v-for="item in provinceList" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="city" class="adEl arElItem">
              <el-select v-model="storeInfoForm.city" placeholder="市" @change="cityChange">
                <el-option v-for="item in cityList" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="areas" class="adEl arElItem">
              <el-select v-model="storeInfoForm.areas" placeholder="区" @change="areasChange">
                <el-option v-for="item in areasList" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="street" class="adEl arElItem">
              <el-select v-model="storeInfoForm.street" placeholder="街道">
                <el-option v-for="item in streetList" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="shopaddr" class="arElItem" style="width: 38%;">
              <el-input v-model="storeInfoForm.shopaddr" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="门店简介" prop="noticemark">
            <el-input class="formTextarea" v-model="storeInfoForm.shopmark" type="textarea" maxlength="200" placeholder="请输入门店简介（最多200字）"></el-input>
          </el-form-item>
          <el-form-item label="门店logo" class="showImg" prop="shoplogo">
            <template style="width: 100%;">
              <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" :picture="storeInfoForm.furl" @uploadSuccess="infoImgSuccess"></Upload>
            </template>
          </el-form-item>
        </el-form>

        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="FromCancel">取消</div>
          <div class="settingConfirm" @click="FromConfirm">确定</div>
        </div>
      </div>

      <!-- 门店设置  -->
      <div class="formItemType" v-if="formType === '1'">
<!--        <p class="formTitle">门店设置</p>-->
        <StoreSetting :storeInfoEdit="storeInfoEdit" @szCancel="FromCancel"></StoreSetting>
      </div>

      <!-- 主体认证 -->
      <div class="formItemType" v-if="formType === '2'">
        <p class="formTitle">主体认证</p>
        <el-form :model="storeIdentity" :rules="storeIdentityRule" ref="identityForm">
          <div class="flexCB">
            <el-form-item label="主体类型" class="detailFormItem" prop="authtype">
              <el-radio-group v-model="storeIdentity.authtype" style="width: 100%;">
                <el-radio :label="1">公司</el-radio>
                <el-radio :label="2">个体</el-radio>
<!--                <el-radio :label="item.value" :key="item.value" v-for="(item) in authtypeRadio">{{item.label}}</el-radio>-->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="公司名称" class="detailFormItem" prop="shopcomname">
              <el-input v-model="storeIdentity.shopcomname"  placeholder="请输入公司名称"></el-input>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="公司地址" class="detailFormItem" prop="shopcomaddr">
              <el-input v-model="storeIdentity.shopcomaddr"  placeholder="请输入公司地址"></el-input>
            </el-form-item>
            <el-form-item label="法人姓名" class="detailFormItem" prop="shopsuper">
              <el-input v-model="storeIdentity.shopsuper"  placeholder="请输入法人姓名"></el-input>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="法人身份证" class="detailFormItem" prop="supercode">
              <el-input v-model="storeIdentity.supercode"  placeholder="请输入法人身份证"></el-input>
            </el-form-item>
            <el-form-item label="统一信用代码" class="detailFormItem" prop="shopcomcode">
              <el-input v-model="storeIdentity.shopcomcode"  placeholder="请输入统一信用代码"></el-input>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="联系人" class="detailFormItem" prop="shopuser">
              <el-input v-model="storeIdentity.shopuser"  placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系人电话" class="detailFormItem" prop="shopuphone">
              <el-input v-model="storeIdentity.shopuphone"  placeholder="请输入联系人电话"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="营业执照" class="showImg" prop="shopcert">
            <template style="width: 100%;">
              <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" :picture="storeIdentity.furl" @uploadSuccess="identImgSuccess"></Upload>
            </template>
          </el-form-item>
        </el-form>
        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="FromCancel">取消</div>
          <div class="settingConfirm" @click="identFromConfirm">确定</div>
        </div>
      </div>
    </div>

<!--    <el-dialog class="mapDialog" :visible.sync="mapShow" width="1000px"  :close-on-click-modal="false" >-->
<!--      <Address :modify="modifyAdd" @close="addressClose" @save="addressSave"></Address>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import Address from '@/components/address'
import Upload from '@/components/upload.vue'
import StoreSetting from '../store/storeSetting'
import { isValDateMobile, isLandline, creditCode, cardIdCheck } from '@/utils/validate.js'
import { sysAreas, sysCity, sysProvince, sysStreets } from '@/api/system'
import { storeInfoAdd, storeInfoEdit, storeIdentAdd, storeIdentEdit, storeIdentQuery } from '@/api/store'
export default {
  name: 'storeForm',
  props: ['storeInfoEdit'],
  components: {
    Address,
    Upload,
    StoreSetting // 门店设置
  },
  data () {
    // 手机号码校验
    const checkPhone = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入手机号码'))
      } else if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else { callback() }
    }
    // 电话号码校验
    const checkTele = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入电话号码'))
      } else if (isLandline(value)[0]) {
        callback(new Error(isLandline(value)[1]))
      } else { callback() }
    }
    // 身份证校验
    const checkCodeId = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入法人身份证'))
      } else {
        if (value.length === 18) {
          let ret = cardIdCheck(value)
          let { status, msg } = ret
          if (status === 0) {
            callback(new Error(msg || '身份证号码格式错误'))
          }
        } else {
          callback(new Error('身份证长度或格式错误'))
        }
      }
      callback()
    }
    const checkCreditCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('统一社会信用代码不能为空'))
      } else if (creditCode(value.trim()) === false) {
        callback(new Error('请输入正确的统一社会信用代码'))
      } else { callback() }
    }
    return {
      formType: '0',
      storeInfoForm: {
        shopid: '', // 门店id
        shopname: '', // 门店名称
        shoptime: '', // 开业时间
        shopuser: '', // 负责人
        superphone: '', // 负责人电话
        shophone: '', // 门店电话
        longitude: '', // 经度
        latitude: '', // 纬度
        logOrLat: '', // 经纬度字符
        province: '', // 省id
        city: '', // 市id
        areas: '', // 县区id
        street: '', // 街道id
        shopaddr: '', // 详细地址
        shopmark: '', // 门店简介
        shoplogo: '' // 门店logo
      },
      storeInfoRule: {
        shopname: [
          { required: true, trigger: 'blur', message: '门店名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        shoptime: [{ required: true, trigger: 'blur', message: '请选择开业时间' }],
        shopuser: [
          { required: true, trigger: 'blur', message: '负责人不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        superphone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        shophone: [{ required: true, validator: checkTele, trigger: 'blur' }],
        logOrLat: [{ required: true, trigger: 'blur', message: '请输入地理定位' }],
        province: [{ required: true, trigger: 'change', message: '请选择省份' }],
        city: [{ required: true, trigger: 'blur', message: '请选择市' }],
        areas: [{ required: true, trigger: 'blur', message: '请选择区县' }],
        street: [{ required: true, trigger: 'blur', message: '请选择街道' }],
        shopaddr: [
          { required: true, trigger: 'blur', message: '详细地址不能为空' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ]
      },
      provinceList: [],
      cityList: [],
      areasList: [],
      streetList: [],
      mapShow: false,
      address: '',
      refresh: false,
      storeIdentity: {
        shopid: '', // 门店id
        authtype: 1, // 主体类型
        shopcomname: '', // 公司名称
        shopcomaddr: '', // 公司地址
        shopsuper: '', // 法人姓名
        supercode: '', // 法人身份证
        shopcomcode: '', // 统一信用码
        shopuser: '', // 联系人
        shopuphone: '', // 联系人电话
        shopcert: '' // 营业执照
      },
      authtypeRadio: [{ label: '公司', value: 1 }, { label: '个体', value: 2 }],
      storeIdentityRule: {
        authtype: { required: true, trigger: 'blur', message: '请选择主体类型' },
        shopcomname: [
          { required: true, trigger: 'blur', message: '公司名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        shopcomaddr: [
          { required: true, trigger: 'blur', message: '公司地址不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        shopsuper: [
          { required: true, trigger: 'blur', message: '法人姓名不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        supercode: [
          { required: true, validator: checkCodeId, trigger: 'blur' }
        ],
        shopcomcode: [{ required: true, validator: checkCreditCode, trigger: 'blur' }],
        shopuser: [
          { required: true, trigger: 'blur', message: '联系人不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        shopuphone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        shopcert: { required: true, trigger: 'blur', message: '请上传营业执照' }
      },
      modifyAdd: null
    }
  },
  created () {
    this.getProvince()
  },
  methods: {
    // 省级列表
    getProvince () {
      sysProvince().then(res => {
        if (res.status === 1) {
          this.provinceList = res.data.data
          if (this.storeInfoEdit !== null) { // 编辑赋值
            this.storeInfoForm = this.storeInfoEdit
            this.storeInfoForm.logOrLat = this.storeInfoEdit.longitude + ',' + this.storeInfoEdit.latitude
            this.provinceChangeEdit(this.storeInfoForm.province)
          }
        }
      })
    },
    // 切换省级
    provinceChange (val) {
      this.cityList = []
      this.areasList = []
      this.streetList = []
      this.storeInfoForm.city = ''
      this.storeInfoForm.areas = ''
      this.storeInfoForm.street = ''
      sysCity({ provinceCode: val }).then(res => {
        if (res.status === 1) {
          this.cityList = res.data.data
        }
      })
    },
    // 切换省级 [编辑默认赋值]
    provinceChangeEdit (val) {
      sysCity({ provinceCode: val }).then(res => {
        if (res.status === 1) {
          this.cityList = res.data.data
          if (this.storeInfoEdit !== null) {
            this.cityChangeEdit(this.storeInfoForm.city)
          }
        }
      })
    },
    // 切换城市
    cityChange (val) {
      this.areasList = []
      this.streetList = []
      this.storeInfoForm.areas = ''
      this.storeInfoForm.street = ''
      sysAreas({ provinceCode: this.storeInfoForm.province, cityCode: val }).then(res => {
        if (res.status === 1) {
          this.areasList = res.data.data
        }
      })
    },
    // 切换城市 [编辑默认赋值]
    cityChangeEdit (val) {
      sysAreas({ provinceCode: this.storeInfoForm.province, cityCode: val }).then(res => {
        if (res.status === 1) {
          this.areasList = res.data.data
          if (this.storeInfoEdit !== null) {
            this.areasChangeEdit(this.storeInfoForm.areas)
          }
        }
      })
    },
    // 切换区县
    areasChange (val) {
      this.streetList = []
      this.storeInfoForm.street = ''
      const params = { provinceCode: this.storeInfoForm.province, cityCode: this.storeInfoForm.city, areaCode: val }
      sysStreets(params).then(res => {
        if (res.status === 1) {
          this.streetList = res.data.data
        }
      })
    },
    // 切换区县[编辑默认赋值]
    areasChangeEdit (val) {
      const params = { provinceCode: this.storeInfoForm.province, cityCode: this.storeInfoForm.city, areaCode: val }
      sysStreets(params).then(res => {
        if (res.status === 1) {
          this.streetList = res.data.data
        }
      })
    },
    // 切换【门店信息-门店设置-主体认证】
    changeForm (type) {
      this.formType = type
      if (this.storeInfoEdit !== null) {
        if (this.formType === '2') {
          this.$nextTick(() => {
            this.$refs.identityForm.resetFields()
          })
          if (this.storeInfoEdit.shopauth === true) {
            storeIdentQuery({ shopid: this.storeInfoEdit.shopid }).then(res => {
              if (res.status === 1) {
                this.storeIdentity = res.data.data[0]
              }
            })
          } else {
            setTimeout(() => {
              this.storeIdentity.shopid = this.storeInfoEdit.shopid
              this.storeIdentity.authtype = 1
            }, 10)
          }
        }
      }
    },
    selectAddress () { this.mapShow = true },
    // 定位确认
    addressSave (res) {
      this.mapShow = false
      this.storeInfoForm.logOrLat = res.longitude + ',' + res.latitude
      this.storeInfoForm.longitude = res.longitude
      this.storeInfoForm.latitude = res.latitude
    },
    addressClose () { this.mapShow = false },
    getLogLat () {
      window.open('https://lbs.amap.com/tools/picker', '_blank')
    },
    // 门店logo
    infoImgSuccess (res) {
      this.storeInfoForm.shoplogo = res.fid
    },
    FromCancel () {
      this.$emit('formCancel')
    },
    // 【门店信息】[新增-编辑]提交
    FromConfirm () {
      this.$refs.storeInfo.validate((valid) => {
        if (!valid) return
        if (this.storeInfoForm.logOrLat) {
          const logLat = this.storeInfoForm.logOrLat.split(',')
          this.storeInfoForm.longitude = logLat[0]
          this.storeInfoForm.latitude = logLat[1]
          const params = {
            shopid: this.storeInfoForm.shopid,
            shopname: this.storeInfoForm.shopname,
            shoptime: this.storeInfoForm.shoptime,
            shopuser: this.storeInfoForm.shopuser,
            superphone: this.storeInfoForm.superphone,
            shophone: this.storeInfoForm.shophone,
            longitude: this.storeInfoForm.longitude,
            latitude: this.storeInfoForm.latitude,
            province: this.storeInfoForm.province,
            city: this.storeInfoForm.city,
            areas: this.storeInfoForm.areas,
            street: this.storeInfoForm.street,
            shopaddr: this.storeInfoForm.shopaddr,
            shopmark: this.storeInfoForm.shopmark,
            shoplogo: this.storeInfoForm.shoplogo
          }
          if (this.storeInfoEdit === null) { // 新增提交
            storeInfoAdd(params).then(res => {
              if (res.status === 1) {
                this.$message.success(res.message)
                this.$emit('formSuccess')
              } else {
                this.$message.error(res.message)
              }
            })
          } else { // 编辑提交
            storeInfoEdit(params).then(res => {
              if (res.status === 1) {
                this.$message.success(res.message)
                this.$emit('formSuccess')
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    // 营业执照
    identImgSuccess (res) {
      this.storeIdentity.shopcert = res.fid
    },
    // 【主体认证】[新增-编辑]提交
    identFromConfirm () {
      this.$refs.identityForm.validate((valid) => {
        if (!valid) return
        const params = {
          shopid: this.storeIdentity.shopid,
          authtype: this.storeIdentity.authtype,
          shopcomname: this.storeIdentity.shopcomname,
          shopcomaddr: this.storeIdentity.shopcomaddr,
          shopsuper: this.storeIdentity.shopsuper,
          supercode: this.storeIdentity.supercode,
          shopcomcode: this.storeIdentity.shopcomcode,
          shopcert: this.storeIdentity.shopcert,
          shopuser: this.storeIdentity.shopuser,
          shopuphone: this.storeIdentity.shopuphone
        }
        if (this.storeInfoEdit.shopauth === false) { // 新增提交
          storeIdentAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        } else { // 编辑提交
          storeIdentEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.adEl{ width: 15%; }
.arElItem{ margin-bottom: -4px; }
.showImg >>> .el-form-item__label{
  width: 100%;
  text-align: left;
}
</style>

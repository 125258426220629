<!-- 地址添加 -->
<template>
  <div class="box100">
    <el-form :model="resFrom" :rules="resFromRule" ref="FromAdd" style="width: 100%;">
      <div class="flexCB" v-if="!isNo">
        <el-form-item label="省" prop="province">
          <el-select v-model="resFrom.province" placeholder="请选择省" @change="proSelect">
              <el-option
                v-for="item in proList"
                :key="item.name"
                :label="item.name"
                :value="item.name">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" prop="city" style="margin-left: 15px;">
          <el-select v-model="resFrom.city" placeholder="请选择市" @change="citySelect">
            <el-option
              v-for="item in cityList"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区(县)" prop="area" style="margin-left: 15px;">
          <el-select v-model="resFrom.area" placeholder="请选择区(县)" @change="areaSelect">
            <el-option
              v-for="item in areaList"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="街道" prop="street" style="margin-left: 15px;">
          <el-select v-model="resFrom.street" placeholder="请选择街道" @change="streetSelect">
            <el-option
              v-for="item in streetList"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="resFrom.address"  placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <div class="flexF">
        <el-form-item label="经度" prop="longitude">
          <el-input v-model="resFrom.longitude"  placeholder="请输入经度"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="latitude" style="margin-left: 15px;">
          <el-input v-model="resFrom.latitude"  placeholder="请输入纬度"></el-input>
        </el-form-item>
      </div>
      <p class="addTip">提示：获取经纬度请点击<span @click="openLL">https://lbs.amap.com/tools/picker</span></p>
    </el-form>

    <div class="flexCE">
      <div class="settingCancel" @click="cancel">取消</div>
      <div class="settingConfirm" @click="fromConfirm">确定</div>
    </div>
  </div>
</template>

<script>
// import { areaQuery, cityQuery, provinceQuery, streetQuery } from '@/http/api/settingSystem'
import { sysProvince, sysCity, sysAreas, sysStreets } from '@/api/system'

export default {
  props: ['modify', 'isNoPca'],
  data () {
    return {
      resFrom: {
        province: '', // 省
        city: '', // 市
        area: '', // 区
        street: '', // 街道
        address: '', // 地址
        longitude: '', // 经度
        latitude: '', // 纬度
      },
      resFromRule: {
        province: [{ required: true, trigger: 'blur', message: '请选择省' }],
        city: [{ required: true, trigger: 'blur', message: '请选择市' }],
        area: [{ required: true, trigger: 'blur', message: '请选择区' }],
        street: [{ required: true, trigger: 'blur', message: '请选择街道' }],
        address: [{ required: true, trigger: 'blur', message: '详细地址不能为空' }],
        longitude: [{ required: true, trigger: 'blur', message: '经度不能为空' }],
        latitude: [{ required: true, trigger: 'blur', message: '纬度不能为空' }]
      },
      proList: [], // 省列表
      cityList: [], // 市列表
      areaList: [], // 区县列表
      streetList: [], // 街道列表
      isNo: false // 无省市区
    }
  },
  watch: {
    modify (val) {
      if (val !== null) {
        this.resFrom = val
        if (this.isNo === false) {
          this.getCity(this.modify.province)
        }
      } else {
        this.resFrom = {
          province: '', // 省
          city: '', // 市
          area: '', // 区
          street: '', // 街道
          address: '', // 地址
          longitude: '', // 经度
          latitude: '' // 纬度
        }
      }
    },
    isNoPca (val) {
      this.isNo = val
    }
  },
  mounted () {
    this.isNo = this.isNoPca
    if (this.modify !== null) {
      this.resFrom = this.modify
      if (this.isNo === false) {
        this.getCity(this.modify.province)
      }
    } else {
      this.resFrom = {
        province: '', // 省
        city: '', // 市
        area: '', // 区
        street: '', // 街道
        address: '', // 地址
        longitude: '', // 经度
        latitude: '' // 纬度
      }
    }
    this.getProvince()
  },
  methods: {
    /* 获取省份 */
    getProvince () {
      sysProvince().then(res => {
        if (res.status === '1') {
          this.proList = res.data
        }
      })
    },
    /* 选择省份-获取城市 */
    proSelect (val) {
      sysCity({ name: val }).then(res => {
        if (res.status === '1') {
          this.cityList = res.data
          this.areaList = []
          this.resFrom.city = ''
          this.resFrom.area = ''
          this.resFrom.street = ''
        }
      })
    },
    /* 选择城市-获取区县 */
    citySelect (val) {
      sysAreas({ name: val }).then(res => {
        if (res.status === '1') {
          this.areaList = res.data
          this.resFrom.area = ''
          this.resFrom.street = ''
        }
      })
    },
    /* 选择区县-获取街道 */
    areaSelect (val) {
      sysStreets({ name: val }).then(res => {
        if (res.status === '1') {
          this.streetList = res.data
          this.resFrom.street = ''
        }
      })
    },
    streetSelect (val) {
      this.resFrom.street = val
    },
    /* 编辑-获取[城市]列表 */
    getCity (val) {
      sysCity({ name: val }).then(res => {
        if (res.status === '1') {
          this.cityList = res.data
          if (this.modify.city !== '') {
            this.getArea(this.modify.city)
          }
        }
      })
    },
    /* 编辑-获取[区县]列表 */
    getArea (val) {
      sysAreas({ name: val }).then(res => {
        if (res.status === '1') {
          this.areaList = res.data
          if (this.modify.area !== '') {
            this.getStreet(this.modify.area)
          }
        }
      })
    },
    /* 编辑-获取[街道]列表 */
    getStreet (val) {
      sysStreets({ name: val }).then(res => {
        if (res.status === '1') {
          this.streetList = res.data
        }
      })
    },
    openLL () {
      window.open('https://lbs.amap.com/tools/picker', '_blank')
    },
    /* 取消 */
    cancel () {
      this.$emit('close')
    },
    /* 确定-保存 */
    fromConfirm () {
      this.$refs.FromAdd.validate((valid) => {
        if (!valid) return
        const params = {
          province: this.resFrom.province, // 省
          city: this.resFrom.city, // 市
          area: this.resFrom.area, // 区
          street: this.resFrom.street, // 街道
          address: this.resFrom.address, // 地址
          longitude: this.resFrom.longitude, // 经度
          latitude: this.resFrom.latitude // 纬度
        }
        // console.log(params)
        this.$emit('save', params)
      })
    }
  }
}
</script>

<style>
.addTip{
  margin-bottom: 20px;
}
.addTip span{
  display: inline-block;
  color: #196df7;
  cursor: pointer;
}
</style>

<template>
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
      </template>
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="keyWord"  placeholder="请输入门店编号/门店名称" class="inputSearch"></el-input>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="storeList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="门店编号"
            prop="shopcode">
          </el-table-column>
          <el-table-column
            label="主体认证"
            prop="shopauth">
            <template slot-scope="scope">
              <span v-if="scope.row.shopauth === true" style="color: #44B557;">已认证</span>
              <span v-if="scope.row.shopauth === false">未认证</span>
            </template>
          </el-table-column>
          <el-table-column
            label="区域"
            prop="province_name,city_name,areas_name">
            <template slot-scope="scope"> {{scope.row.province_name}}{{scope.row.city_name}} {{scope.row.areas_name}}</template>
          </el-table-column>
          <el-table-column
            label="门店名称"
            prop="shopname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="负责人"
            prop="shopuser">
          </el-table-column>
          <el-table-column
            label="负责电话"
            prop="superphone">
          </el-table-column>
          <el-table-column
            label="门店类型"
            prop="shoptype">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="shopisok">
            <template slot-scope="scope">
              <span v-if="scope.row.shopisok === true" style="color: #44B557;">已上架</span>
              <span v-if="scope.row.shopisok === false">已下架</span>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[2].power">
                <el-button type="text" size="small" @click="showEdit(scope.row)">编辑</el-button>
              </template>
              <template v-if="roleBtn[3].power">
                <el-button type="text" size="small" v-if="scope.row.shopisok === false" style="color: #44B557;" @click="handPut(scope.row)">上架</el-button>
                <el-button type="text" size="small" v-if="scope.row.shopisok === true" style="color: red;" @click="handOff(scope.row)">下架</el-button>
              </template>
              <template v-if="roleBtn[1].power">
                <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <div class="box100" v-if="showForm">
      <StoreForm @formCancel="formCancel" @formSuccess="formSuccess" :storeInfoEdit="storeInfoEdit"></StoreForm>
    </div>
  </div>
</template>

<script>
import StoreForm from '@/components/store/storeForm'
import { storeListQuery, storeUpAndLow, storeInfoDel } from '@/api/store'
export default {
  components: {
    StoreForm // 店铺表单
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      keyWord: '',
      showForm: false,
      storeList: [],
      storeInfoEdit: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        shopname: this.keyWord,
        page_index: this.page,
        page_size: this.size
      }
      storeListQuery(params).then(res => {
        if (res.status === 1) {
          this.storeList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    // 搜索
    handleSearch () {
      this.init()
    },
    // 重置
    handleReset () {
      this.keyWord = ''
      this.init()
    },
    // 翻页
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 点击展示【添加】
    handleAdd () {
      this.showForm = true
      this.storeInfoEdit = null
    },
    // 点击展示【编辑】
    showEdit (row) {
      this.showForm = true
      this.storeInfoEdit = { ...row }
    },
    formCancel () {
      this.showForm = false
    },
    // 门店信息提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 点击【上架】
    handPut (row) { this.UpAndLow(row) },
    // 点击【下架】
    handOff (row) { this.UpAndLow(row) },
    // 上下架提交
    UpAndLow (row) {
      storeUpAndLow({ shopid: row.shopid, shopisok: row.shopisok }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 点击【删除】
    handDel (row) {
      this.$confirm('此操作将永久删除该门店, 是否继续?', '删除门店', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        storeInfoDel({ shopid: row.shopid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style>

</style>

<!-- 门店设置 -->
<template>
  <div class="box100">
    <el-form class="settingInfoBox" :model="setUp" :rules="storeSetupRule" ref="setupForm">
      <div class="formClass">
        <p class="formTitle">平时营业时间</p>
        <el-radio v-model="setUp.businesst.busTime.type" label="1" @change="yeSelect">打样休息</el-radio>
        <div class="flexCB" style="margin-top: 25px;">
          <div class="detailFormItem">
            <el-radio v-model="setUp.businesst.busTime.type" label="2" @change="yeSelect">以周显示</el-radio>
            <el-select class="selectTime" v-model="setUp.businesst.busTime.value.yeWeekS" placeholder="开始时间" :disabled="yeDateShow">
              <el-option v-for="item in week" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <span style="margin: 0 5px;">至</span>
            <el-select class="selectTime" v-model="setUp.businesst.busTime.value.yeWeekE" placeholder="结束时间" :disabled="yeDateShow">
              <el-option v-for="item in week" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
          <div class="detailFormItem">
            <span style="margin: 0 5px;">工作时间</span>
            <el-time-select class="selectTime" v-model="setUp.businesst.busTime.value.yeStart" :disabled="yeDateShow"
                            :picker-options="{start: '05:00',step: '01:00',end: '12:00'}" placeholder="开始时间">
            </el-time-select>
            <span style="margin: 0 5px;">至</span>
            <el-time-select class="selectTime" v-model="setUp.businesst.busTime.value.yeEnd" :disabled="yeDateShow"
                            :picker-options="{start: '12:00',step: '01:00',end: '24:00'}" placeholder="结束时间">
            </el-time-select>
          </div>
        </div>
      </div>
      <div class="formClass">
        <p class="formTitle">自定义营业时间</p>
        <el-form-item label="名称" class="detailFormItem">
          <el-input v-model="setUp.businesst.customTime.name"  placeholder="请输入名称"></el-input>
        </el-form-item>
        <div class="flexCB">
          <div class="detailFormItem flexCB">
            <span>以日期显示</span>
            <el-date-picker v-model="setUp.businesst.customTime.zdyDate" type="daterange" style="width: 70%;" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="detailFormItem">
            <span style="margin: 0 5px;">工作时间</span>
            <el-time-select class="selectTime" v-model="setUp.businesst.customTime.zdyStart"
                            :picker-options="{start: '00:00',step: '01:00',end: '12:00'}" placeholder="开始时间">
            </el-time-select>
            <span style="margin: 0 5px;">至</span>
            <el-time-select class="selectTime" v-model="setUp.businesst.customTime.zdyEnd"
                            :picker-options="{start: '12:00',step: '01:00',end: '24:00'}" placeholder="结束时间">
            </el-time-select>
          </div>
        </div>
      </div>
      <div class="formClass" style="padding-bottom: 2px;">
        <div class="formTitle">门店概况 <p class="zdyP" @click="rowAdd('1')">自定义添加</p></div>
        <div class="flexCB">
          <el-form-item label="门店面积" class="detailFormItem">
            <el-input v-model="setUp.introduce.basic[0].value"  placeholder="请输入门店面积"><el-button slot="append">㎡</el-button></el-input>
          </el-form-item>
          <el-form-item label="容纳人数" class="detailFormItem">
            <el-input v-model="setUp.introduce.basic[1].value"  placeholder="请输入容纳人数"><el-button slot="append">人</el-button></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="开业时间" class="detailFormItem">
<!--            <el-input v-model="setUp.introduce.basic[2].value"  placeholder="请输入开业时间"></el-input>-->
            <el-date-picker v-model="setUp.introduce.basic[2].value" type="date" value-format="yyyy-MM-dd" placeholder="请选择开业时间" style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="最近装修" class="detailFormItem">
<!--            <el-input v-model="setUp.introduce.basic[3].value"  placeholder="请输入最近装修时间"></el-input>-->
            <el-date-picker v-model="setUp.introduce.basic[3].value" type="date" value-format="yyyy-MM-dd" placeholder="请选择最近装修时间" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </div>
        <div class="flexF">
          <div class="detailFormItem zdySur" v-for="(item,index) in setUp.introduce.customSurvey" :key="item.index">
            <el-input v-model="item.label"  placeholder="请输入标题（最多6字)" class="zdyLabel"></el-input>
            <span class="surDel" @click="rowDel('1', index)">删除</span>
            <el-input v-model="item.value"  placeholder="请输入内容（最多20字）"></el-input>
          </div>
        </div>
      </div>
      <div class="formClass" style="padding-bottom: 8px;">
        <p class="formTitle">周边交通</p>
        <p class="lineName">地铁线</p>
        <div class="flexCB" v-for="(dt,dtIndex) in setUp.traffic.metro" :key="dt.label" style="margin-bottom: 16px;">
          <el-input v-model="dt.value"  placeholder="请输入如：2号线、小寨站D口、向东100米"></el-input>
          <p class="zdyAddBtn" v-if="dtIndex === 0" @click="rowAdd('2')">添加</p>
          <p class="zdyDelBtn" v-if="dtIndex !== 0" @click="rowDel('2', dtIndex)">删除</p>
        </div>
        <p class="lineName">公交线</p>
        <div class="flexCB" v-for="(gj,gjIndex) in setUp.traffic.transit" :key="gj.label" style="margin-bottom: 16px;">
          <el-input v-model="gj.value"  placeholder="请输入请输入如：600路、小寨站、向西100米"></el-input>
          <p class="zdyAddBtn" v-if="gjIndex === 0" @click="rowAdd('3')">添加</p>
          <p class="zdyDelBtn" v-if="gjIndex !== 0" @click="rowDel('3', gjIndex)">删除</p>
        </div>
      </div>
      <div class="formClass">
        <p class="formTitle">经营政策/保障</p>
        <div class="flexSC">
          <div class="detailFormItem">
            <el-form-item label="经营政策（最多添加3个标签）" class="labelItem">
              <el-input v-model="policy"  placeholder="请输入经营政策标签" style="width: calc(100% - 70px);"></el-input>
              <p class="zdyAddBtn" @click="tagAdd('1')">添加</p>
            </el-form-item>
            <div class="flexAC" :class="setUp.policy.busPolicy.length === 0 ? '': 'tagBox'">
              <el-tag v-for="(tag,index) in setUp.policy.busPolicy" :key="tag" closable type="info" @close="tagDel('1',index)">{{tag}}</el-tag>
            </div>
          </div>
          <div class="detailFormItem">
            <el-form-item label="保障（最多添加3个标签）" class="labelItem">
              <el-input v-model="ensure"  placeholder="请输入保障标签" style="width: calc(100% - 70px);"></el-input>
              <p class="zdyAddBtn" @click="tagAdd('2')">添加</p>
            </el-form-item>
            <div class="flexAC" :class="setUp.policy.guarantee.length === 0 ? '': 'tagBox'">
              <el-tag v-for="(tag,index) in setUp.policy.guarantee" :key="tag" closable type="info" @close="tagDel('2',index)">{{tag}}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="formClass">
        <p class="formTitle">设施服务</p>
        <div class="flexSC" style="margin-bottom: 24px">
          <div class="detailFormItem">
            <el-form-item label="付款方式（最多添加3个标签）" class="labelItem">
              <el-input v-model="payMode"  placeholder="请输入付款方式标签" style="width: calc(100% - 70px);"></el-input>
              <p class="zdyAddBtn" @click="tagAdd('3')">添加</p>
            </el-form-item>
            <div class="flexAC" :class="setUp.service.payMode.length === 0 ? '': 'tagBox'">
              <el-tag v-for="(tag,index) in setUp.service.payMode" :key="tag" closable type="info" @close="tagDel('3',index)">{{tag}}</el-tag>
            </div>
          </div>
          <div class="detailFormItem">
            <el-form-item label="电子设备（最多添加3个标签）" class="labelItem">
              <el-input v-model="eleEquip"  placeholder="请输入电子设备标签" style="width: calc(100% - 70px);"></el-input>
              <p class="zdyAddBtn" @click="tagAdd('4')">添加</p>
            </el-form-item>
            <div class="flexAC" :class="setUp.service.equList.length === 0 ? '': 'tagBox'">
              <el-tag v-for="(tag,index) in setUp.service.equList" :key="tag" closable type="info" @close="tagDel('4',index)">{{tag}}</el-tag>
            </div>
          </div>
        </div>
        <div class="detailFormItem">
          <el-form-item label="个性化服务（最多添加3个标签）" class="labelItem">
            <el-input v-model="personal"  placeholder="请输入个性化服务标签" style="width: calc(100% - 70px);"></el-input>
            <p class="zdyAddBtn" @click="tagAdd('5')">添加</p>
          </el-form-item>
          <div class="flexAC" :class="setUp.service.perList.length === 0 ? '': 'tagBox'">
            <el-tag v-for="(tag,index) in setUp.service.perList" :key="tag" closable type="info" @close="tagDel('5',index)">{{tag}}</el-tag>
          </div>
        </div>
      </div>
      <div class="formClass">
        <p class="formTitle">门店标签</p>
        <div class="detailFormItem">
          <el-form-item label="门店标签（最多添加3个标签）" class="labelItem">
            <el-input v-model="storeTag"  placeholder="请输入门店标签" style="width: calc(100% - 70px);"></el-input>
            <p class="zdyAddBtn" @click="tagAdd('6')">添加</p>
          </el-form-item>
          <div class="flexAC" :class="setUp.slabel.storeTagList.length === 0 ? '': 'tagBox'">
            <el-tag v-for="(tag,index) in setUp.slabel.storeTagList" :key="tag" closable type="info" @close="tagDel('6',index)">{{tag}}</el-tag>
          </div>
        </div>
      </div>
      <div class="formClass" style="border-bottom: 0px;">
        <p class="formTitle">门店展示图片</p>
        <div class="flexF">
          <div style="width: 25%;margin-bottom: 10px;" v-for="(item, index) in setUp.shopimg" :key="index" @click="getIndex(index)">
            <Upload :fileType="'jpg/png/jpeg'" :picture="item.furl" @uploadSuccess="ImgSuccess"></Upload>
          </div>
        </div>
      </div>
    </el-form>
    <!--    -->
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
      <div class="settingCancel" @click="settCancel">取消</div>
      <div class="settingConfirm" @click="setConfirm">确定</div>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { storeSetAE, storeSetQuery } from '@/api/store'
export default {
  name: 'storeSetting',
  components: {
    Upload
  },
  props: ['storeInfoEdit'],
  data () {
    return {
      storeSetupRule: {},
      week: ['周一', '周二', '周三', '周四', '周五', '周六', '周天'],
      yeDateShow: true,
      policy: '',
      ensure: '',
      payMode: '',
      eleEquip: '',
      personal: '',
      storeTag: '',
      imgIndex: 0,
      setUp: {
        shopid: '', // 门店id
        // 营业时间
        businesst: {
          busTime: { // 平时营业时间
            type: '1',
            value: {
              yeWeekS: '',
              yeWeekE: '',
              yeStart: '',
              yeEnd: ''
            }
          },
          customTime: { // 自定义营业时间
            name: '',
            zdyDate: [],
            zdyStart: '',
            zdyEnd: ''
          }
        },
        // 门店概况
        introduce: {
          // 基本概况
          basic: [
            { label: '门店面积', value: '' }, { label: '容纳人数', value: '' },
            { label: '开业时间', value: '' }, { label: '最近装修', value: '' }
          ],
          // 自定义概况
          customSurvey: []
        },
        // 周边交通
        traffic: {
          metro: [{ label: '地铁线', value: '' }], // 地铁
          transit: [{ label: '公交线', value: '' }] // 公交
        },
        // 经营政策
        policy: {
          busPolicy: [], // 经营政策
          guarantee: [] // 保障
        },
        // 设置服务
        service: {
          payMode: [], // 支付方式
          equList: [], // 电子设备
          perList: [] // 个性化服务
        },
        // 门店标签
        slabel: { storeTagList: [] },
        shopimg: [
          { fid: '', furl: '' }, { fid: '', furl: '' }, { fid: '', furl: '' }, { fid: '', furl: '' },
          { fid: '', furl: '' }, { fid: '', furl: '' }, { fid: '', furl: '' }, { fid: '', furl: '' }
        ]
      }
    }
  },
  watch: {
    'setUp.businesst.busTime.type' (val) {
      this.yeSelect(val)
    },
    'setUp.businesst.busTime.value.yeStart' (val) {
      if (val === null) {
        this.setUp.businesst.busTime.value.yeStart = ''
      }
    },
    'setUp.businesst.busTime.value.yeEnd' (val) {
      if (val === null) {
        this.setUp.businesst.busTime.value.yeEnd = ''
      }
    },
    'setUp.businesst.customTime.zdyDate' (val) {
      if (val === null) {
        this.setUp.businesst.customTime.zdyDate = []
      }
    },
    'setUp.businesst.customTime.zdyStart' (val) {
      if (val === null) {
        this.setUp.businesst.customTime.zdyStart = ''
      }
    },
    'setUp.businesst.customTime.zdyEnd' (val) {
      if (val === null) {
        this.setUp.businesst.customTime.zdyEnd = ''
      }
    }
  },
  created () {
    storeSetQuery({ shopid: this.storeInfoEdit.shopid }).then(res => {
      if (res.status === 1) {
        if (res.data.data.length > 0) {
          this.setUp = res.data.data[0]
        }
      }
    })
  },
  methods: {
    yeSelect (val) {
      if (val === '1') { this.yeDateShow = true }
      else { this.yeDateShow = false }
    },
    // [概况自定义1-地铁路线2-公交线路3]添加
    rowAdd (type) {
      const obj = { label: '', value: '' }
      if (type === '1') {
        this.setUp.introduce.customSurvey.push(obj)
      } else if (type === '2') {
        this.setUp.traffic.metro.push(obj)
      } else if (type === '3') {
        this.setUp.traffic.transit.push(obj)
      }
    },
    // [概况自定义1-地铁路线2-公交线路3]删除
    rowDel (type, eq) {
      if (type === '1') {
        this.setUp.introduce.customSurvey.splice(eq, 1)
      } else if (type === '2') {
        this.setUp.traffic.metro.splice(eq, 1)
      } else if (type === '3') {
        this.setUp.traffic.transit.splice(eq, 1)
      }
    },
    // [经营政策1-保障2-付款方式3-电子设备4-个性化服务5-门店标签6]标签[添加]
    tagAdd (type) {
      if (type === '1') {
        if (this.policy !== '') {
          if (this.setUp.policy.busPolicy.length < 3) {
            this.setUp.policy.busPolicy.push(this.policy)
            this.policy = ''
          }
        }
      } else if (type === '2') {
        if (this.ensure !== '') {
          if (this.setUp.policy.guarantee.length < 3) {
            this.setUp.policy.guarantee.push(this.ensure)
            this.ensure = ''
          }
        }
      } else if (type === '3') {
        if (this.payMode !== '') {
          if (this.setUp.service.payMode.length < 3) {
            this.setUp.service.payMode.push(this.payMode)
            this.payMode = ''
          }
        }
      } else if (type === '4') {
        if (this.eleEquip !== '') {
          if (this.setUp.service.equList.length < 3) {
            this.setUp.service.equList.push(this.eleEquip)
            this.eleEquip = ''
          }
        }
      } else if (type === '5') {
        if (this.personal !== '') {
          if (this.setUp.service.perList.length < 3) {
            this.setUp.service.perList.push(this.personal)
            this.personal = ''
          }
        }
      } else if (type === '6') {
        if (this.storeTag !== '') {
          if (this.setUp.slabel.storeTagList.length < 3) {
            this.setUp.slabel.storeTagList.push(this.storeTag)
            this.storeTag = ''
          }
        }
      }
    },
    // [经营政策1-保障2-付款方式3-电子设备4-个性化服务5-门店标签6]标签[删除]
    tagDel (type, eq) {
      if (type === '1') {
        this.setUp.policy.busPolicy.splice(eq, 1)
      } else if (type === '2') {
        this.setUp.policy.guarantee.splice(eq, 1)
      } else if (type === '3') {
        this.setUp.service.payMode.splice(eq, 1)
      } else if (type === '4') {
        this.setUp.service.equList.splice(eq, 1)
      } else if (type === '5') {
        this.setUp.service.perList.splice(eq, 1)
      } else if (type === '6') {
        this.setUp.slabel.storeTagList.splice(eq, 1)
      }
    },
    getIndex (index) {
      this.imgIndex = index
    },
    ImgSuccess (res) {
      this.setUp.shopimg[this.imgIndex].fid = res.fid
      this.setUp.shopimg[this.imgIndex].furl = res.furl
    },
    settCancel () {
      this.$emit('szCancel')
    },
    // 门店设置提交
    setConfirm () {
      this.setUp.introduce.basic.forEach((item) => {
        if (item.value === null) {
          item.value = ''
        }
      })
      const params = {
        shopid: this.storeInfoEdit.shopid,
        businesst: this.setUp.businesst, // 营业时间
        introduce: this.setUp.introduce, // 门店概括
        traffic: this.setUp.traffic, // 周边交通
        policy: this.setUp.policy, // 经营政策
        service: this.setUp.service, // 设置服务
        slabel: this.setUp.slabel, // 门店标签
        shopimg: this.setUp.shopimg // 门店照片
      }
      storeSetAE(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.$emit('szCancel')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.zdyP{
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #3C84EF;
  cursor: pointer;
}
.selectTime{
  width: 30%;
}
.flexRow{
  width: 100%;
  display: flex;
}
.zdySur{
  margin-right: 4%;
  margin-bottom: 22px;
  position: relative;
}
.zdySur:nth-child(even){
  margin-right: 0;
}
.surDel{
  color: #F44C8E;
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
}
.zdyLabel{
  margin-bottom: 8px;
  width: 50%;
}
.lineName{
  color: #606266;
  margin-bottom: 4px;
}
</style>
